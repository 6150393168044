import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAvEOCuLsGn_qtn5J-6y-XzqMd-idXbtRA",
  authDomain: "lyft-cap.firebaseapp.com",
  databaseURL: "https://lyft-cap-default-rtdb.firebaseio.com",
  projectId: "lyft-cap",
  storageBucket: "lyft-cap.appspot.com",
  messagingSenderId: "843162392509",
  appId: "1:843162392509:web:ba7852a2bf386e6fb524d4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
