import React, { useState, useEffect } from 'react'
import { ApiData, MappedData } from '../model'
import { database } from './../firebase'
import { ref, child, get } from "firebase/database";
import './Table.css';

import {
    GroupingState,
    useReactTable,
    // getPaginationRowModel,
    getFilteredRowModel,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    getGroupedRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'


function Table({ rawData }: { rawData: ApiData[] }) {
    // Helper functions
    function formatNumber(value: number): string {
        const sigFigures = value.toPrecision(4);
        const decimalPlaces = value.toFixed(2);

        // Choose the format with fewer characters
        return sigFigures.length > decimalPlaces.length ? sigFigures : decimalPlaces;
    }


    const rerender = React.useReducer(() => ({}), {})[1]

    const [underlyingMapping, setUnderlyingMapping] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const fetchMapping = async () => {
            try {
                const dbRef = ref(database);
                const snapshot = await get(child(dbRef, '/underlying'));
                if (snapshot.exists()) {
                    setUnderlyingMapping(snapshot.val());
                } else {
                    console.log("No data available");
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchMapping();
    }, []);

    const [apiData, setApiData] = useState<MappedData[]>([]);

    useEffect(() => {
        // Convert apiData to MappedData using underlyingMapping
        const mappedData: MappedData[] = rawData.map(item => {
            const mappedSymbol = underlyingMapping[item.token_contract];
            const underlyingSymbol = mappedSymbol || item.symbol;
            return {
                ...item,
                underlying_symbol: underlyingSymbol,
            };
        });

        // Set the state with mapped data
        setApiData(mappedData);
    }, [rawData, underlyingMapping]);

    const [sorting, setSorting] = useState<SortingState>([]);


    const columns = React.useMemo<ColumnDef<ApiData>[]>(
        () => [
            {
                header: 'date',
                id: 'date',
                accessorKey: 'date',
                cell: info => info.getValue(),
            },
            {
                header: 'wallet',
                id: 'wid',
                accessorKey: 'wid',
                cell: info => info.getValue<string>().slice(0,5) + "..." + info.getValue<string>().slice(-5),
            },
            {
                header: 'chain',
                id: 'chain',
                accessorKey: 'chain',
                cell: info => info.getValue(),
            },
            {
                header: 'protocol',
                id: 'protocol',
                accessorKey: 'protocol',
                cell: info => info.getValue(),
            },
            // {
            //     header: 'position_id',
            //     id: 'position_id',
            //     accessorKey: 'position_id',
            //     cell: info => info.getValue(),
            // },
            {
                header: 'position',
                id: 'position',
                accessorKey: 'position',
                cell: info => info.getValue(),
            },
            {
                header: 'pos_type',
                id: 'pos_type',
                accessorKey: 'pos_type',
                cell: info => info.getValue(),
            },
            {
                header: 'symbol',
                id: 'symbol',
                accessorKey: 'symbol',
                cell: info => info.getValue(),
            },
            {
                header: 'token_contract',
                id: 'token_contract',
                accessorKey: 'token_contract',
                cell: info => info.getValue(),
            },
            {
                header: 'underlying_symbol',
                id: 'underlying_symbol',
                accessorKey: 'underlying_symbol',
                cell: info => info.getValue(),
            },
            {
                header: 'price',
                id: 'price',
                accessorKey: 'price',
                cell: info => formatNumber(info.getValue<number>()),
                aggregatedCell: info => formatNumber(info.getValue<number>()),
                enableGrouping: false,
                aggregationFn: 'mean',
            },
            {
                header: 'amount',
                id: 'amount',
                accessorKey: 'amount',
                cell: info => formatNumber(info.getValue<number>()),
                aggregatedCell: info => formatNumber(info.getValue<number>()),
                enableGrouping: false,
                aggregationFn: 'sum',
            },
            {
                header: 'value',
                id: 'value',
                accessorKey: 'value',
                cell: info => formatNumber(info.getValue<number>()),
                aggregatedCell: info => formatNumber(info.getValue<number>()),
                enableGrouping: false,
                aggregationFn: 'sum',
            },
            {
                header: '% value',
                id: '% value',
                accessorKey: 'value',
                cell: info => {
                    const total: number = info.table.getCoreRowModel().flatRows.reduce((sum, current) => sum + current.original.value, 0);
                    return Math.round(info.getValue() as number / total * 10000) / 100
                },
                aggregatedCell: info => {
                    const total: number = info.table.getCoreRowModel().flatRows.reduce((sum, current) => sum + current.original.value, 0);
                    return Math.round(info.getValue() as number / total * 10000) / 100
                },
                enableGrouping: false,
                aggregationFn: 'sum',
            },
        ],
        []
    )

    const [data, setData] = useState<ApiData[]>(apiData)

    useEffect(() => {
        setData(apiData)
    }, [apiData])

    const [grouping, setGrouping] = useState<GroupingState>([])

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            grouping,
        },
        onSortingChange: setSorting,
        onGroupingChange: setGrouping,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        debugTable: true,
    })

    return (
        <div className='table-container'>
            <h1>Main</h1>
            {apiData.length > 0 ? (
                <table>
                    <thead className='main'>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <span>
                                                    {header.column.getCanGroup() ? (
                                                        // If the header can be grouped, let's add a toggle
                                                        <button
                                                            {...{
                                                                onClick: header.column.getToggleGroupingHandler(),
                                                                style: {
                                                                    cursor: 'pointer',
                                                                },
                                                            }}
                                                        >
                                                            {header.column.getIsGrouped()
                                                                ? `🛑(${header.column.getGroupedIndex()}) `
                                                                : `👊 `}
                                                        </button>
                                                    ) : null}{' '}

                                                    {header.column.getCanSort() ? (
                                                        // If the header can be grouped, let's add a toggle
                                                        <div
                                                            onClick={header.column.getToggleSortingHandler()}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {{
                                                                asc: '🔼',
                                                                desc: '🔽',
                                                            }[header.column.getIsSorted() as string ?? null]}
                                                        </div>
                                                    ) : null}

                                                </span>
                                            )}
                                        </th>

                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <thead className='subtotal'>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    if (header.id === 'value') {
                                        return (
                                            <th key={header.id} colSpan={header.colSpan}>
                                                
                                                {table.getCoreRowModel().flatRows.reduce((sum, current) => sum + current.original.value, 0)}
                                            </th>
                                        )
                                    } else {
                                        // console.log(header.column.columnDef.aggregationFn)
                                        return (
                                            <th key={header.id} colSpan={header.colSpan}>
                                                
                                            </th>
                                        )
                                    }

                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td
                                                {...{
                                                    key: cell.id,
                                                    style: {
                                                        background: cell.getIsGrouped()
                                                            ? '#0aff0082'
                                                            : cell.getIsAggregated()
                                                                ? '#ffa50078'
                                                                : cell.getIsPlaceholder()
                                                                    ? '#ff000042'
                                                                    : 'white',
                                                    },
                                                }}
                                            >
                                                {cell.getIsGrouped() ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <>
                                                        <button
                                                            {...{
                                                                onClick: row.getToggleExpandedHandler(),
                                                                style: {
                                                                    cursor: row.getCanExpand()
                                                                        ? 'pointer'
                                                                        : 'normal',
                                                                },
                                                            }}
                                                        >
                                                            {row.getIsExpanded() ? '👇' : '👉'}{' '}
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}{' '}
                                                            ({row.subRows.length})
                                                        </button>
                                                    </>
                                                ) : cell.getIsAggregated() ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    flexRender(
                                                        cell.column.columnDef.aggregatedCell ??
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )
                                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : (
                <span> Loading data... </span>
            )}
        </div>
    );
}

export default Table;