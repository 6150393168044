import React, { useState, useEffect } from 'react';
import './App.css';
import { ApiData, HistData } from './model'
import { database } from './firebase'
import { ref, child, get } from "firebase/database";

import Table from './components/Table';
import Chart from './components/Chart';


function App() {

  const [apiData, setApiData] = useState<ApiData[]>([]);
  const [histData, setHistData] = useState<HistData>();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://fetchdata-moywcnwidq-uc.a.run.app');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setApiData(data);
        console.log(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const fetchHistorical = async () => {
      const dbRef = ref(database);
      get(child(dbRef, `/user/buffboii`)).then((snapshot) => {
        if (snapshot.exists()) {
          setHistData(snapshot.val());
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    };

    fetchHistorical();

    
  }, []); // Empty dependency array to run the effect only once on component mount

  


  return (
    <div className="App">
      <div className="test">
        <h1>Test</h1>
        {/* <span>{JSON.stringify(apiData)}</span>
        <span>{JSON.stringify(histData)}</span> */}
      </div>
      <div className="main">
        <Chart histData={histData ?? []}/>
        <Table rawData={apiData}/>
      </div>
    </div>
  );
}

export default App;
