import React, { useState, useEffect } from 'react'
import { ApiData } from '../model'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import './Chart.css';

function Chart({ histData }: any) {

    const [data, setData] = useState(histData)

    useEffect(() => {

        setData(histData)
    }, [histData])

    interface DateSumObject {
        date: string;
        total_nav: number;
    }

    function convertData(data: { [date: string]: ApiData[] }): DateSumObject[] {
        const result: DateSumObject[] = [];

        // Iterate over each date in the data object
        for (const date in data) {
            if (Object.prototype.hasOwnProperty.call(data, date)) {
                const items = data[date];
                let total_nav = 0;
                // Iterate over the items for each date
                items.forEach(item => {
                    total_nav += item.value;
                });
                result.push({ date, total_nav });
            }
        }

        return result;
    }

    return (
        <div className='chart-container'>
            <h1>Chart</h1>
            {Object.keys(histData).length > 0  ? (
                <LineChart width={800} height={400} data={convertData(data)}>
                    <Line type="monotone" dataKey="total_nav" stroke="#8884d8" />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="date" />
                    <YAxis domain={['auto', 'auto']} />
                    <Tooltip />
                </LineChart>
            ) : (
                <span> Loading data... </span>
            )}
        </div>
    );
}

export default Chart;